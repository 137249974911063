<template>
<tbody :class="isprint==0?'table-font':'table-font-print'">
    <tr v-if="item!=null" :style="activeemi(item)">
        <td>{{index+1}}. </td>
        <td>{{ item.id }}</td>
        <td><i class='bx bx-rupee'></i> {{ item.tobepaid }}</td>
        <td><i class='bx bx-calendar-alt'></i> {{ moment(item.emidate).format("DD-MM-YYYY") }}</td>
        <td><i class='bx bx-rupee'></i>{{item.payment!=null?item.payment.amount:"0.0" }}</td>
        
        <td><i class='bx bx-calendar-alt'></i> {{ item.receiveddate!=null ? moment(item.receiveddate).format("DD-MM-YYYY") :''}}</td>
        <td>{{item.payment!=null?item.payment.receiptno:''}}</td>
        <td>{{item.payment?.collectedby!=null?item.payment.collectedby.name:''}}</td>
        <td><i class='bx bx-calendar-alt'></i>{{item.payment!=null?moment(item.payment.created_at).format("DD-MM-YYYY"):''}}<br>
            <i class='bx bx-time-five'></i> {{item.payment!=null?moment(item.payment.created_at).format("h:mm:ss A"):''}}
        </td>
        <td v-if="isprint==0"><button class="badge message"><i class="fa fa-envelope"></i> {{ countmessages }}</button></td>
        <td>{{item.payment!=null?item.payment.enteredby!=null?item.payment.enteredby.name:'':''}}</td>
        <!-- <td style="padding-left:0px;padding-right:0">
            <button v-if="item.paymenttransactionid==0 && showindex==item.sequence" class="btn btnsearch btn-view" type="button" @click="payemi()" style="background-color:#179b17!important;padding:7px;font-size:9px;border-radius:25px;">
                <i v-if="showpaymentform==false" class="fa fa-arrow-up"></i>
                <i v-else class="fa fa-arrow-down"></i> Pay Emi
            </button>
            <button class="badge btn-view" v-if="item.paymenttransactionid!=0 && item.payment.paymentregister!=null">{{item.payment.paymentregister.name}}</button>
        </td> -->
        <td style="padding-left:0px;padding-right:0" v-if="isprint==0">
            <!-- <template v-if="item.paymenttransactionid == 0 && item.payment?.paymentregister==null"> -->
                <button v-if="item.isactive==1 &&  loginusercheck(132) && item.payment==null" class="btn btnsearch btn-view" type="button" @click="payemi()" 
                        style="background-color:#179b17!important;padding:7px;font-size:9px;border-radius:25px;width:100%">
                        <i v-if="showpaymentform==false" class="fa fa-arrow-up"></i>
                        <i v-else class="fa fa-arrow-down"></i> Pay Emi
                    
                </button>
            <!-- </template> -->
            <button class="badge btn-view" v-if="item.payment?.paymentregister!=null">{{item.payment.paymentregister.name}}</button>
            <!-- <button v-if="item.approveid==0 && loginusercheck(132)" class="btn btnsearch btn-view" type="button" @click="approvalchange(item)" style="background-color:#179b17!important;padding:7px;font-size:9px;border-radius:25px;width:100%">
                Approve
            </button> -->
        </td>
    </tr>
    <tr v-if="isprint==0">
        <td colspan="4" v-if="loginusercheck(134) ">
            <button class="btn btnsearch btn-info emi-detail-btn" style="width:auto" type="button" @click="visitcomment()" v-if="item.paymenttransactionid!=0">
                <i v-if="visitcomments==false" class="fa fa-plus"></i>
                <i  v-else class="fa fa-minus"></i> View Conversation
            </button>
            <button class="btn btnsearch btn-danger emi-detail-btn" style="width:30%" type="button" @click="visitcomment()" v-if="item.isactive==1">
                <i v-if="visitcomments==false" class="fa fa-plus"></i>
                <i  v-else class="fa fa-minus"></i>  Conversation
            </button>
        </td>
        <td  colspan="8" class="text-right" v-if="item.payment!=null && loginusercheck(133) && isprint==0">
            <button type="button" class="approve-btn" v-if="item.disablereverse==1"> Approved</button>
            <button type="button" class="badge btn-danger" v-else @click="reverseemi(item.id)"><i class='bx bx-repost'></i> Reverse Emi</button>
        </td>
    </tr>
    <tr v-if="showpaymentform">
        <td colspan="11">
            <loan-emi-payment-form :emiitem="item"></loan-emi-payment-form>
        </td>
    </tr>
    <tr v-if="visitcomments">
        <td colspan="12">
            <loan-emi-visit-comment :emiid="item.id" :followed="followed" :emi="item" :commentlist="commentlist"></loan-emi-visit-comment>
        </td>
    </tr>
</tbody>
</template>
<script>
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
import moment from "moment";
import LoanEmiVisitComment from './LoanEmiVisitComment.vue';
import LoanEmiPaymentForm from './LoanEmiPaymentForm.vue';
export default {
    mixins:[Constants],
    components:{LoanEmiPaymentForm,LoanEmiVisitComment},
    data() {
        return {
            showpaymentform:false,
            visitcomments:false,
            moment:moment,
            paymentid:1,
            countmessages:0,
            commentlist:[]
        }
    },
    props:['emiid','index','showindex','followed','isprint'],
    watch:{
        countmessages:function(){
            if(this.item!=null){
                return this.item.visitcomments.length
            }
            return 0
        }
    },
    computed:{
        ...mapGetters([
		    'edititem','registers'
	    ]),
        item(){
            if(this.edititem!=null && this.edititem.emis!=null){
                let emi = this.edititem.emis.find(block=>block.id==this.emiid)
                if(typeof emi!=='undefined')
                    return emi
            }
            return null;
        },
        // countmessages(){
        //     if(this.item!=null){
        //         return this.item.visitcomments.length
        //     }
        //     return 0
        // }
    },
    methods:{
        activeemi(item){
            if(item.isactive==1){
                return 'background-color:#16f14f82'
            }
        },
        reverseemi(emiid){
            this.$store.commit('assignloadingstatus',1)
            if(confirm("Do you really want to delete ?")==true){
                this.$notify({
                text:'Payment Reversed',
                type:'warn',
                duration:'8000',
                speed:'800',
            })
                let param={emiid:emiid}
                window.axios.post('api/loan/loanemidelete',param)
                    .then(response=>this.processResponsedelete(response.data))
                    .catch(error=>this.reverseError(error));
            }
            
        },
        reverseError(error){
            this.$store.commit('assignloadingstatus',1)
            console.log(error)
            this.$notify({
                text:'Something Went Wrong',
                type:'error',
                duration:'8000',
                speed:'800',
                
            })
            this.$store.commit("assignviewno",0)
        },
        processResponsedelete(data){
            if(data==true){
                this.$notify({
                    text:'Payment Reversed Successfully',
                    type:'success',
                    duration:'8000',
                    speed:'800',
                    
                })
                this.$store.commit('assignloadingstatus',0)
                this.$store.commit("assignviewno",1)
            }
            this.$store.commit('assignloadingstatus',0)
        },
        payemi(){
            this.showpaymentform = !this.showpaymentform
            //this.$store.commit('assignedititem2',this.item)
            this.count++
        },
        visitcomment(){
            let param = {emiid:this.item.id}
            this.$http.post('api/loan/emi/visitcomments/fetch',param)
            .then((response) => this.processDetailResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
            this.visitcomments = !this.visitcomments;
        },
        processDetailResponse(data){
            this.commentlist=data
            // this.$store.commit('assignlist',data)
        },
        approvalchange(item){
            console.log('items')
            console.log(item)
            window.axios.post('api/loan/emi/approveconversation',item)
            .then(response=>this.processapprovecommentresponse(response.data))
            .catch(error=>console.log(error))
            
            
        },
        processapprovecommentresponse(data){
             this.approveData = data;
           
        }
        
    }
}
</script>
<style scoped>
.text-right{
    text-align: right;
}
.approve-btn{
    background: green;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 4px 10px;
    margin-right: 30px;
    cursor: not-allowed;
}
</style>